import React, { useState } from 'react'
import {Link} from 'react-router-dom'
import {
    HeaderContainer,
    TopHeader,
    Banner,
    InsuranceMenu,
    HeaderContant,
    Logo,
    Nav,
    HeaderContact,
    HeaderContactContainer,
    LanguageChange,
    HeaderMenu,
    BannerContainer,
    BannerContent,
    InsuranceInfo,
    InsuranceInfoContainer,
    InsuranceOptions,
    InsuranceInfoText,
    ButtonContainer,
    LanguagePtbr,
    LanguageEnus,
    TopHeaderMobile,
    LogoMobile,
    MenuMobile,
    MenuCard} from './style'

    import { i18n } from '../../translate/i18n'

    import LogoHeader from '../../asset/img/logo-header.png'
    import IconBrazil from '../../asset/img/brazil.png'
    import IconUsa from '../../asset/img/usa.png'
    import IconMailSmall from '../../asset/img/icon-mail-small.svg'
    import IconWhatsappSmall from '../../asset/img/icon-whatsapp-small.svg'
    import IconMenuMobile from '../../asset/img/icon-menu.svg'

    import BannerAuto from '../../asset/img/banner-auto.jpg'
    import BannerTruck from '../../asset/img/banner-truck.jpg'
    import BannerHome from '../../asset/img/banner-home.jpg'
    import BannerTravel from '../../asset/img/banner-travel.jpg'
    import BannerLife from '../../asset/img/banner-life.jpg'
    import BannerHealth from '../../asset/img/banner-health.jpg'
    import BannerBusiness from '../../asset/img/banner-business.jpg'
    import BannerCommunQuestions from '../../asset/img/banner-commumquestions.jpg'
    import BannerAbout from '../../asset/img/banner-about.jpg'
    import BannerQuote from '../../asset/img/banner-quote.jpg'
    import BannerContact from '../../asset/img/banner-contact.jpg'
    import BackgroundVideo from '../../asset/img/background-video.png'

    import ButtonPrimary from '../ButtonPrimary'

    import BannerVideo from '../../asset/video/RoyalEagleInsurance.mp4'


export default function Header(){

    const [infoCard, setInfoCard] = useState(false)
    const [menuCardContent, setmenuCardContent] = useState('')
    const [infoheght, setInfoheght] = useState()
    const [language, setLanguage] = useState(localStorage.getItem('i18nextLng'))
    const [menuCard, setMenuCard] = useState(false)

    const urlPath = window.location.pathname

    function handleMenuCard(){
        menuCard === false ? setMenuCard(true) : setMenuCard(false)
    }

    function handleLanguages(languageSelect){
        localStorage.setItem('i18nextLng', languageSelect)
        window.location = window.location
    }

    let timeNow = ''

    function handleInfoCard(cardContent){
        setInfoCard(true)
        if(cardContent){
            setInfoheght('204px')
            setmenuCardContent(cardContent)            
        }
    }

    function handleInfoCardClose(){
        setInfoCard(false)
    
    }

    function hadleChangeHeight(){
        setInfoheght('188px')
    }

    const menuHealth = {
        name: 'health',
        text: i18n.t('header.insuranceCard.health')
    }

    const menuLife = {
        name: 'life',
        text: i18n.t('header.insuranceCard.life')
    }

    const menuTravel = {
        name: 'travel',
        text: i18n.t('header.insuranceCard.travel')
    }

    const menuAuto = {
        name: 'auto',
        text: i18n.t('header.insuranceCard.auto')
    }

    const menuHome = {
        name: 'home',
        text: i18n.t('header.insuranceCard.home')
    }

    const menuBusiness = {
        name: 'business',
        text: i18n.t('header.insuranceCard.business')
    }

    const menuTruck = {
        name: 'truck',
        text: i18n.t('header.insuranceCard.truck')
    }

    function bannerSwitch(){
        switch(urlPath){
            case '/' : 
                return <video autoPlay loop muted src={BannerVideo}  type="video/mp4" poster={BackgroundVideo}></video>;
            case '/auto' :
                return <img src={BannerAuto} alt="" />;
            case '/truck' :
                return <img src={BannerTruck} alt="" />;
            case '/home' :
                return <img src={BannerHome} alt="" />;
            case '/travel' :
                return <img src={BannerTravel} alt="" />;
            case '/life' :
                return <img src={BannerLife} alt="" />;
            case '/health' :
                return <img src={BannerHealth} alt="" />;
            case '/business' :
                return <img src={BannerBusiness} alt="" />;
            case '/commonquestions' :
                return <img src={BannerCommunQuestions} alt="" />;
            case '/about' :
                return <img src={BannerAbout} alt="" />;
            case '/quote' :
                return <img src={BannerQuote} alt="" />;
            case '/contact' :
                return <img src={BannerContact} alt="" />;
        }
    }

    return(
        <HeaderContainer>
            <HeaderContant>

                <TopHeaderMobile>
                    <Link to='/'>
                        <LogoMobile src={LogoHeader}></LogoMobile>
                    </Link>
                    <MenuMobile onClick={handleMenuCard}>
                        <img src={IconMenuMobile} alt="" />
                    </MenuMobile>
                </TopHeaderMobile>

                <MenuCard active={menuCard}>

                    <HeaderContactContainer>
                        <div>
                            <Link to={i18n.t('header.menuContact.phoneLink')} target="blank">
                                <img src={IconWhatsappSmall} alt="" />
                                <p>{i18n.t('header.menuContact.phone')}</p>
                            </Link>
                        </div>
                    </HeaderContactContainer>

                    <LanguageChange>
                        <LanguagePtbr selected={language === 'pt-BR' && ('true')} onClick={event => handleLanguages('pt-BR')}>
                            <img src={IconBrazil} alt="" />
                        </LanguagePtbr>
                        <LanguageEnus selected={language === 'en-US' && ('true')} onClick={event => handleLanguages('en-US')}>
                            <img src={IconUsa} alt="" />
                        </LanguageEnus>
                    </LanguageChange>

                    <HeaderMenu>
                        {/* <Link><li>{i18n.t('header.menu.service')}</li></Link> */}
                        <Link to="/commonquestions"><li>{i18n.t('header.menu.commonQuestions')}</li></Link>
                        <Link to="/quote"><li>{i18n.t('header.menu.quote')}</li></Link>
                        <Link to="/about"><li>{i18n.t('header.menu.about')}</li></Link>
                        <Link to="/contact"><li>{i18n.t('header.menu.contact')}</li></Link>
                    </HeaderMenu>

                </MenuCard>

                <TopHeader>
                    <Logo>
                        <Link to="/"><img src={LogoHeader} alt="" /></Link>
                    </Logo>
                    <Nav>

                        <HeaderContact>

                            <HeaderContactContainer>
                                <div>
                                    <Link to="mailto:royaleagleinsurance@gmail.com">
                                        <img src={IconMailSmall} alt="" />
                                        <p>edimara@royaleagleinsurance.com</p>
                                    </Link>
                                </div>
                                <div>
                                    <Link to={i18n.t('header.menuContact.phoneLink')} target="blank">
                                        <img src={IconWhatsappSmall} alt="" />
                                        <p>{i18n.t('header.menuContact.phone')}</p>
                                    </Link>
                                </div>
                            </HeaderContactContainer>

                            <LanguageChange>
                                <LanguagePtbr selected={language === 'pt-BR' && ('true')} onClick={event => handleLanguages('pt-BR')}>
                                    <img src={IconBrazil} alt="" />
                                </LanguagePtbr>
                                <LanguageEnus selected={language === 'en-US' && ('true')} onClick={event => handleLanguages('en-US')}>
                                    <img src={IconUsa} alt="" />
                                </LanguageEnus>
                            </LanguageChange>

                        </HeaderContact>

                        <HeaderMenu>
                            {/* <Link><li>{i18n.t('header.menu.service')}</li></Link> */}
                            <Link to="/commonquestions"><li>{i18n.t('header.menu.commonQuestions')}</li></Link>
                            <Link to="/quote"><li>{i18n.t('header.menu.quote')}</li></Link>
                            <Link to="/about"><li>{i18n.t('header.menu.about')}</li></Link>
                            <Link to="/contact"><li>{i18n.t('header.menu.contact')}</li></Link>
                        </HeaderMenu>

                    </Nav>
                </TopHeader>

                <Banner smallBanner={urlPath === '/' ? false : true}>
                    {bannerSwitch()}
                </Banner>

                <BannerContainer smallBanner={urlPath === '/' ? false : true}>
                    {urlPath === '/' && (

                        <BannerContent>
                            
                            <h3>{i18n.t('header.bannerTitle.normalText')}
                            <span>{i18n.t('header.bannerTitle.boldText')}</span>
                            </h3>   

                            <h4>
                                {i18n.t('header.bannerSubTitle.normalText')}<span>{i18n.t('header.bannerSubTitle.boldText')}</span>
                            </h4>

                        </BannerContent>
                        
                    )}
                </BannerContainer>

                <InsuranceMenu smallBanner={urlPath === '/' ? false : true}>

                    <InsuranceInfo >

                        {infoCard && (
                        <div onMouseOver={event => handleInfoCard('')} onMouseOut={handleInfoCardClose} style={{height: infoheght}}>

                            <InsuranceInfoContainer background={menuCardContent.name} onMouseOver={hadleChangeHeight}>
                                    
                                <InsuranceInfoText>
                                    <h4>{menuCardContent.name}</h4>
                                    <p>
                                        {menuCardContent.text}
                                    </p>
                                </InsuranceInfoText>
                                <ButtonContainer>
                                    <Link to={'/' + menuCardContent.name}>
                                        <ButtonPrimary type="large">Get a Quote</ButtonPrimary>
                                    </Link>
                                </ButtonContainer>

                            </InsuranceInfoContainer>

                        </div>
                    )}

                    </InsuranceInfo>
                    <InsuranceOptions>

                        <Link to="/health" onMouseOver={event => handleInfoCard(menuHealth)} onMouseOut={handleInfoCardClose}>
                            <li >
                                <div>                                
                                    <svg xmlns="http://www.w3.org/2000/svg" width="59" height="54" viewBox="0 0 59 54" fill="none">
                                        <path d="M2.00539 17.7377C1.89966 13.6793 3.35044 9.58586 6.35712 6.48846C12.167 0.503846 21.5863 0.503846 27.3962 6.48846L29.4999 8.65567L31.6037 6.48846C37.4135 0.503846 46.833 0.503846 52.6427 6.48846C58.4524 12.4731 58.4524 22.1761 52.6427 28.1608L29.4999 52L23.7142 46.0401" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M37 29.5001H29.2221L23.3888 22L17.5555 37L11.7221 29.5001H2" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <p>health</p>
                                </div>
                            </li>
                        </Link>
                        <Link to="/life" onMouseOver={event => handleInfoCard(menuLife)} onMouseOut={handleInfoCardClose}>
                            <li>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="68" height="54" viewBox="0 0 68 54" fill="none">
                                        <path d="M26.8889 25.7148C26.8889 30.3696 30.0727 34.143 34 34.143C37.9274 34.143 41.1112 30.3696 41.1112 25.7148C41.1112 24.9225 41.0189 24.1557 40.8465 23.4287" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M44.6665 19.3338C44.6665 25.5401 48.6462 30.5714 53.5554 30.5714C58.4646 30.5714 62.4443 25.5401 62.4443 19.3338C62.4443 18.2774 62.329 17.255 62.1134 16.2856" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M5.57104 19.3338C5.57104 25.5401 9.55074 30.5714 14.46 30.5714C19.3692 30.5714 23.3489 25.5401 23.3489 19.3338C23.3489 18.2774 23.2335 17.255 23.018 16.2856" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M31.8977 22.424C33.4902 23.6517 37.6306 25.616 41.4525 23.6517C43.3262 22.6887 40.2581 16.2856 34.2864 16.2856C29.6158 16.2856 26.0867 19.806 27.0475 25.6995C27.086 25.9357 27.2948 26.1065 27.5281 26.0532C28.032 25.9379 28.7929 25.6156 29.509 24.8794" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M50.9027 16.7627C52.7762 18.5484 57.7575 22.838 61.8002 19.8574C64.2221 18.0716 60.395 9.14307 53.3696 9.14307C47.8773 9.14307 44.5484 12.5534 44.6535 19.3741C44.6577 19.6428 44.8768 19.8258 45.133 19.7448C46.3893 19.3476 49.6091 17.9703 50.5593 16.7627" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M11.807 16.7627C13.6805 18.5484 18.6618 22.838 22.7045 19.8574C25.1264 18.0716 21.2993 9.14307 14.2739 9.14307C8.78165 9.14307 5.45269 12.5534 5.55784 19.3741C5.56198 19.6428 5.78106 19.8258 6.03729 19.7448C7.29356 19.3476 10.5134 17.9703 11.4636 16.7627" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M34.0002 39.1094L29.2594 35.5938M34.0002 39.1094L38.7409 35.5938M34.0002 39.1094V52M29.2594 35.5938L30.4446 33.25M29.2594 35.5938L23.3335 39.1094M38.7409 35.5938L37.5557 33.25M38.7409 35.5938L44.6669 39.1094M23.3335 39.1094V52M23.3335 39.1094L27.8721 42.4751C27.9993 42.5694 28.0742 42.7184 28.0742 42.8767V52M44.6669 39.1094V52M44.6669 39.1094L40.1283 42.4751C40.0011 42.5694 39.9261 42.7184 39.9261 42.8767V52" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M53.5557 37.2677L48.578 33.2499M53.5557 37.2677L58.5335 33.2499M53.5557 37.2677V51.9999M48.578 33.2499L49.8224 30.5713M48.578 33.2499L46.9187 34.1427M58.5335 33.2499L57.2891 30.5713M58.5335 33.2499L66.0002 37.2677M66.0002 37.2677V51.9999M66.0002 37.2677L61.2084 41.1355C61.0908 41.2304 61.0224 41.3734 61.0224 41.5245V51.9999" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M14.4445 37.2677L9.46668 33.2499M14.4445 37.2677L19.4223 33.2499M14.4445 37.2677V51.9999M9.46668 33.2499L10.7111 30.5713M9.46668 33.2499L2 37.2677M19.4223 33.2499L18.1778 30.5713M19.4223 33.2499L21.0815 34.1427M2 37.2677V51.9999M2 37.2677L6.79183 41.1355C6.90943 41.2304 6.97779 41.3734 6.97779 41.5245V51.9999" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M18.7845 10.4718C19.4099 9.59408 19.778 8.51879 19.778 7.35714C19.778 4.39847 17.3902 2 14.4447 2C11.4991 2 9.11133 4.39847 9.11133 7.35714C9.11133 8.51879 9.47942 9.59409 10.1048 10.4718" stroke="white" stroke-width="2.5"/>
                                    </svg>
                                    <p>life</p>
                                </div>
                            </li>
                        </Link>
                        <Link to="/travel"  onMouseOver={event => handleInfoCard(menuTravel)} onMouseOut={handleInfoCardClose}>
                            <li>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                        <path d="M47.8333 13.1113L47.1253 14.6229C46.6063 15.7309 45.2229 16.1174 44.2048 15.4386L43.6392 15.0616M24.4725 18.6669C23.6046 20.4025 23.4988 20.4994 25.0007 21.7283M35.9324 50.2658L37.7808 46.7589C38.485 44.9981 38.9481 43.15 39.1576 41.265L39.2102 40.7928C39.6722 36.6337 40.3107 33.6979 37.7808 30.4783" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M2 23.0524L10.5213 21.6322C14.0835 21.0385 17.1718 24.1269 16.5781 27.689L16.2241 29.8129C15.5904 33.6155 17.5108 37.3868 20.9589 39.1108C23.7197 40.4913 25.1516 43.6042 24.4029 46.5987L23.0526 51.9997" stroke="white" stroke-width="2.5"/>
                                        <path d="M34.6523 3.19288C32.2405 2.41828 29.6691 2 27 2C13.1929 2 2 13.1929 2 27C2 40.8071 13.1929 52 27 52C40.8071 52 52 40.8071 52 27C52 21.3847 50.1487 16.2017 47.0231 12.0283" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M32.8129 26.6088L44.3515 6.62341C44.9038 5.66682 44.576 4.44364 43.6194 3.89135L43.3313 3.72497L43.0431 3.55859C42.0865 3.00631 40.8633 3.33406 40.311 4.29065L28.7724 24.2761" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M35.4796 12.66L23.0794 11.9157C22.7013 11.893 22.3428 12.0859 22.1535 12.4139L21.8486 12.942C21.5528 13.4543 21.764 14.1101 22.3033 14.3535L31.9804 18.7207" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M39.5197 14.9923L46.3644 25.359C46.5731 25.6751 46.5853 26.082 46.3959 26.41L46.091 26.9381C45.7952 27.4504 45.1217 27.5954 44.6413 27.2501L36.0206 21.053" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M28.7516 28.9778L23.1178 27.0719L24.2842 25.0517L28.7142 24.377" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M28.7515 28.9779L33.2189 32.904L34.3853 30.8837L32.7546 26.7099" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                    </svg>
                                    <p>travel</p>
                                </div>
                            </li>
                        </Link>
                        <Link to="/auto"  onMouseOver={event => handleInfoCard(menuAuto)} onMouseOut={handleInfoCardClose}>
                            <li>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="75" height="54" viewBox="0 0 75 54" fill="none">
                                        <path d="M37.5 2H20.2211C19.46 2 18.7649 2.43195 18.4279 3.11432L12.9174 14.271C12.8423 14.4231 12.7482 14.5649 12.6374 14.6932L2.48689 26.4367C2.17282 26.8001 2 27.2643 2 27.7446V43.75C2 44.8546 2.89543 45.75 4 45.75H37.5" stroke="white" stroke-width="2.5"/>
                                        <path d="M37.5 2H54.7789C55.54 2 56.2351 2.43195 56.5721 3.11432L62.0826 14.271C62.1577 14.4231 62.2518 14.5649 62.3626 14.6932L72.5131 26.4367C72.8272 26.8001 73 27.2643 73 27.7446V43.75C73 44.8546 72.1046 45.75 71 45.75H37.5" stroke="white" stroke-width="2.5"/>
                                        <path d="M12.8043 14.5H62.1957M8.17391 20.75L15.7001 23.7976C15.8263 23.8487 15.9406 23.9249 16.0362 24.0217L18.2065 26.2188M37.5 31.6875L34.7067 28.8597C34.5188 28.6695 34.2626 28.5625 33.9952 28.5625H20.9395C20.6722 28.5625 20.416 28.4555 20.2281 28.2653L18.2065 26.2188M3.54348 27L4.79334 28.2653C4.98121 28.4555 5.23742 28.5625 5.50476 28.5625H15.4735C15.7408 28.5625 15.997 28.4555 16.1849 28.2653L18.2065 26.2188M2 33.25H5.08696M12.8043 36.375L10.011 33.5472C9.82313 33.357 9.56692 33.25 9.29959 33.25H5.08696M5.08696 33.25V38.5C5.08696 39.0523 5.53467 39.5 6.08696 39.5H11.2609M15.8913 45.75L20.2281 41.3597C20.416 41.1695 20.6722 41.0625 20.9395 41.0625H37.5M2 41.0625V51C2 51.5523 2.44772 52 3 52H14.8913C15.4436 52 15.8913 51.5523 15.8913 51V47.3125M11.2609 14.5C11.2609 13.6698 11.2609 12.5229 11.2609 11.8744C11.2609 11.5983 11.037 11.375 10.7609 11.375H5.58696C5.31081 11.375 5.08696 11.5989 5.08696 11.875V15.7035C5.08696 15.9181 5.22382 16.1087 5.42709 16.1773L9.71739 17.625" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M62.1957 14.5H12.8043M66.8261 20.75L59.2999 23.7976C59.1737 23.8487 59.0594 23.9249 58.9638 24.0217L56.7935 26.2188M37.5 31.6875L40.2933 28.8597C40.4812 28.6695 40.7374 28.5625 41.0048 28.5625H54.0605C54.3278 28.5625 54.584 28.4555 54.7719 28.2653L56.7935 26.2188M71.4565 27L70.2067 28.2653C70.0188 28.4555 69.7626 28.5625 69.4952 28.5625H59.5265C59.2592 28.5625 59.003 28.4555 58.8151 28.2653L56.7935 26.2188M73 33.25H69.913M62.1957 36.375L64.989 33.5472C65.1769 33.357 65.4331 33.25 65.7004 33.25H69.913M69.913 33.25V38.5C69.913 39.0523 69.4653 39.5 68.913 39.5H63.7391M59.1087 45.75L54.7719 41.3597C54.584 41.1695 54.3278 41.0625 54.0605 41.0625H37.5M73 41.0625V51C73 51.5523 72.5523 52 72 52H60.1087C59.5564 52 59.1087 51.5523 59.1087 51V47.3125M63.7391 14.5V11.875C63.7391 11.5989 63.963 11.375 64.2391 11.375H69.413C69.6892 11.375 69.913 11.5989 69.913 11.875V15.7035C69.913 15.9181 69.7762 16.1087 69.5729 16.1773L65.2826 17.625" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                    </svg>
                                    <p>auto</p>
                                </div>
                            </li>
                        </Link>
                        <Link to="/home"  onMouseOver={event => handleInfoCard(menuHome)} onMouseOut={handleInfoCardClose}>
                            <li>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="67" height="53" viewBox="0 0 67 53" fill="none">
                                        <path d="M16.4005 21.0085L33.1198 1.44477C33.3194 1.21122 33.6804 1.21122 33.88 1.44477L50.5993 21.0085C50.8766 21.333 50.6461 21.8333 50.2192 21.8333H44.6831C44.539 21.8333 44.402 21.7712 44.307 21.6629L33.8759 9.76231C33.6767 9.53508 33.3231 9.53508 33.1239 9.76231L22.6928 21.6629C22.5978 21.7712 22.4608 21.8333 22.3168 21.8333H16.7806C16.3537 21.8333 16.1232 21.333 16.4005 21.0085Z" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M56.7826 19.0557H64.0729C64.4708 19.0557 64.7093 18.6135 64.4907 18.281L56.9307 6.781C56.8383 6.64035 56.6813 6.55566 56.5129 6.55566H38.9783M17.0652 19.0557H2.92706C2.52917 19.0557 2.29069 18.6135 2.50926 18.281L10.0693 6.781C10.1617 6.64035 10.3187 6.55566 10.4871 6.55566H28.0217M49.9348 19.0557H56.8186M65 51.0001H3.36957M2 51.0001H3.36957M3.36957 51.0001V47.3334C3.36957 47.0573 3.59342 46.8334 3.86957 46.8334H22.5435M22.5435 21.8334V49.6112M44.4565 21.8334V32.9446M44.4565 37.1112V49.6112M28.0217 49.6112V33.4446C28.0217 33.1684 28.2456 32.9446 28.5217 32.9446H38.4783C38.7544 32.9446 38.9783 33.1684 38.9783 33.4446V49.6112M30.7609 41.2779V42.6668M49.9348 27.6668H54.0435C54.7999 27.6668 55.413 28.2886 55.413 29.0557V37.389C55.413 38.1561 54.7999 38.7779 54.0435 38.7779H49.9348C49.1784 38.7779 48.5652 38.1561 48.5652 37.389V29.0557C48.5652 28.2886 49.1784 27.6668 49.9348 27.6668ZM12.087 38.7779H17.9348C18.2109 38.7779 18.4348 38.554 18.4348 38.2779V28.1668C18.4348 27.8906 18.2109 27.6668 17.9348 27.6668H12.087C11.8108 27.6668 11.587 27.8906 11.587 28.1668V38.2779C11.587 38.554 11.8108 38.7779 12.087 38.7779Z" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M7.47827 19.0557V46.8334M59.5218 19.0557V49.6112" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M37.7282 24.6112C37.7282 25.2211 37.6056 25.5033 37.5169 25.6327C37.4386 25.7469 37.3058 25.862 36.9996 25.958C36.6692 26.0616 36.2156 26.1174 35.5929 26.1371C35.1747 26.1503 34.7436 26.1468 34.2667 26.143C34.0244 26.141 33.7703 26.139 33.5 26.139C33.2297 26.139 32.9756 26.141 32.7333 26.143C32.2563 26.1468 31.8253 26.1503 31.4071 26.1371C30.7844 26.1174 30.3308 26.0616 30.0003 25.958C29.6942 25.862 29.5614 25.7469 29.4831 25.6327C29.3944 25.5033 29.2717 25.2211 29.2717 24.6112C29.2717 22.2168 31.1812 20.3057 33.5 20.3057C35.8187 20.3057 37.7282 22.2168 37.7282 24.6112Z" stroke="white" stroke-width="2.5"/>
                                    </svg>
                                    <p>home</p>
                                </div>
                            </li>
                        </Link>
                        <Link to="/business"  onMouseOver={event => handleInfoCard(menuBusiness)} onMouseOut={handleInfoCardClose}>
                            <li>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                        <path d="M12.4167 20.478C12.4167 24.0799 10.0848 26.9998 7.20833 26.9998C4.33185 26.9998 2 24.0799 2 20.478" stroke="white" stroke-width="2.5"/>
                                        <path d="M22.8334 20.478C22.8334 24.0799 20.5016 26.9998 17.6251 26.9998C14.7486 26.9998 12.4167 24.0799 12.4167 20.478" stroke="white" stroke-width="2.5"/>
                                        <path d="M31.1666 20.478C31.1666 24.0799 29.3011 26.9998 26.9999 26.9998C24.6987 26.9998 22.8333 24.0799 22.8333 20.478" stroke="white" stroke-width="2.5"/>
                                        <path d="M41.5834 20.478C41.5834 24.0799 39.2516 26.9998 36.3751 26.9998C33.4986 26.9998 31.1667 24.0799 31.1667 20.478" stroke="white" stroke-width="2.5"/>
                                        <path d="M51.9999 20.478C51.9999 24.0799 49.6681 26.9998 46.7916 26.9998C43.9151 26.9998 41.5833 24.0799 41.5833 20.478" stroke="white" stroke-width="2.5"/>
                                        <path d="M3.04167 16.1304L2 20.4783M3.04167 16.1304H13.4583M3.04167 16.1304L9.29167 8.52174M50.9583 16.1304H40.5417M50.9583 16.1304L52 20.4783M50.9583 16.1304L44.7083 8.52174M12.4167 20.4783L13.4583 16.1304M13.4583 16.1304H22.8333M13.4583 16.1304L16.5833 8.52174M22.8333 20.4783V16.1304M22.8333 16.1304H31.1667M22.8333 16.1304L23.875 8.52174M31.1667 20.4783V16.1304M31.1667 16.1304H40.5417M31.1667 16.1304L30.125 8.52174M41.5833 20.4783L40.5417 16.1304M40.5417 16.1304L37.4167 8.52174M9.29167 8.52174H16.5833M9.29167 8.52174V2.5C9.29167 2.22386 9.51552 2 9.79167 2H44.2083C44.4845 2 44.7083 2.22386 44.7083 2.5V8.52174M44.7083 8.52174H37.4167M16.5833 8.52174H23.875M23.875 8.52174H30.125M30.125 8.52174H37.4167M13.4583 5.26087H40.5417M6.16667 27V51.5C6.16667 51.7761 6.39052 52 6.66667 52H11.375M16.1667 52H47.3333C47.6095 52 47.8333 51.7761 47.8333 51.5V27M32.2083 50.913V36.7826M42.625 50.913V36.7826M32.2083 36.7826V32.9348C32.2083 32.6586 32.4322 32.4348 32.7083 32.4348H42.125C42.4011 32.4348 42.625 32.6586 42.625 32.9348V36.7826M32.2083 36.7826H42.625M11.875 43.3043H27.5417C27.8178 43.3043 28.0417 43.0805 28.0417 42.8043V32.9348C28.0417 32.6586 27.8178 32.4348 27.5417 32.4348H11.875C11.5989 32.4348 11.375 32.6586 11.375 32.9348V42.8043C11.375 43.0805 11.5989 43.3043 11.875 43.3043Z" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                    </svg>
                                    <p>business & commercial</p>
                                </div>
                            </li>
                        </Link>
                        <Link to="/truck"  onMouseOver={event => handleInfoCard(menuTruck)} onMouseOut={handleInfoCardClose}>
                            <li>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="75" height="52" viewBox="0 0 75 52" fill="none">
                                        <path d="M9.71739 2H2.5C2.22386 2 2 2.22386 2 2.5V31.4113M12.8043 43.7951H2.5C2.22386 43.7951 2 43.5712 2 43.2951V36.8808M27.5188 43.7951H49.8478M52.9348 43.7951H49.8478M67.6493 43.7951H72.5C72.7761 43.7951 73 43.5712 73 43.2951V23.859C73 23.7381 72.9562 23.6213 72.8768 23.5303L65.7815 15.3978M49.8478 11.2878H61.9683C62.1128 11.2878 62.2501 11.3502 62.3451 11.4591L65.7815 15.3978M49.8478 11.2878V31.4113M49.8478 11.2878V2.5C49.8478 2.22386 49.624 2 49.3478 2H15.8913M49.8478 43.7951V31.4113M65.7815 15.3978H57.0433C56.7671 15.3978 56.5433 15.6217 56.5433 15.8978V24.7195C56.5433 24.9956 56.7671 25.2195 57.0433 25.2195H71.4565M56.0217 29.8634H60.6522M2 31.4113H49.8478M2 31.4113H8.4971C8.77324 31.4113 8.9971 31.6352 8.9971 31.9113V33.8808C8.9971 35.5377 7.65396 36.8808 5.9971 36.8808H2M2 31.4113V36.8808" stroke="white" stroke-width="2.5" stroke-linecap="round"/>
                                        <path d="M26.989 43.795C26.989 47.3827 24.09 50.2848 20.5216 50.2848C16.9532 50.2848 14.0542 47.3827 14.0542 43.795C14.0542 40.2073 16.9532 37.3052 20.5216 37.3052C24.09 37.3052 26.989 40.2073 26.989 43.795Z" stroke="white" stroke-width="2.5"/>
                                        <path d="M67.1196 43.795C67.1196 47.3827 64.2206 50.2848 60.6522 50.2848C57.0838 50.2848 54.1848 47.3827 54.1848 43.795C54.1848 40.2073 57.0838 37.3052 60.6522 37.3052C64.2206 37.3052 67.1196 40.2073 67.1196 43.795Z" stroke="white" stroke-width="2.5"/>
                                    </svg>
                                    <p>truck</p>
                                </div>
                            </li>
                        </Link>

                    </InsuranceOptions>

                </InsuranceMenu>
            </HeaderContant>
        </HeaderContainer>
    )
}