import {createGlobalStyle} from "styled-components";

export const GlobalStyles = createGlobalStyle`

*{
    --container-width: 1170px;
    --text-xsmall: 14px;
    --text-small: 24px;
    --text-medium: 32px;
    --text-large: 40px;
    --text-xlarge: 48px;

    --text-light: 200;
    --text-normal: 300;
    --text-bold: 600;

    --black: #242424;
    --white: #ffffff;

    --blue-1100: #181A2B; 
    --blue-1000: #171666; 
    --blue-900: #392A80;
    --blue-500: #9076D7;
    --blue-700: #644FAB;
    --blue-100: #C5CAFF ;
    --blue-50: #F6F4FF ;
    
    --yellow-100: #FFD88B;
    --yellow-300: #FFDA89;

    --red-700: #FF274E;

}

@media screen and (max-width: 480px){
    
    html, body{
        overflow-x: hidden;
    }
    
}

body{
    font-family: Outfit;
    font-weight: 400;
    font-size: 16px;
    color: var(--black); 
}

p{
    line-height: 24px;
}

a{
    text-decoration: none;
    
}

`

